<template>
  <div class="home-container">
    <v-row no-gutters class="mb-3 trial-container px-8">
      <v-col cols="12" offset-md="1" md="4" class="my-3">
        <div class="openSans-bold fz-20" v-text="$vuetify.lang.t('$vuetify.landingPage.labels.startTitle')"></div>
        <div class="openSans-400 fz-16 mt-5" v-text="$vuetify.lang.t('$vuetify.landingPage.labels.startSubtitle')"></div>
        <v-btn class="mt-7" large color="primary" depressed to="/create-instance">{{ $vuetify.lang.t('$vuetify.landingPage.buttons.startTrial') }}</v-btn>
      </v-col>
      <v-col md="2"></v-col>
      <v-col cols="12" md="4" align="center">
        <div class="video-container">
        <iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" type="text/html" src="https://www.youtube.com/embed/7CA31PZ_3D0?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://youtubeembedcode.com"><div><small><a href="https://youtubeembedcode.com/de/">https://youtubeembedcode.com/de/</a></small></div><div><small><a href="https://nyacasinoutansvensklicens.se/">nyacasinoutansvensklicens.se</a></small></div></iframe>
        </div>
      </v-col>
      <v-col md="1"></v-col>
    </v-row>
    <v-row class="second-row-container px-8" no-gutters>
      <v-col cols="12" offset-md="1" md="4">
        <v-img max-height="317" max-width="417" class="mx-auto" src="@/assets/images/landing-illustration.svg"/>
      </v-col>
      <v-col md="2"></v-col>
      <v-col cols="12" md="4">
        <div class="openSans-bold fz-30" v-text="$vuetify.lang.t('$vuetify.landingPage.labels.literatureSurveillance')"></div>
        <div class="openSans-400 fz-16 mt-5" v-text="$vuetify.lang.t('$vuetify.landingPage.labels.literatureSurveillanceDescription')"></div>
      </v-col>
      <v-col md="1"></v-col>
    </v-row>
  </div>
</template>

<script>
export default {

  name: 'Home',

  data () {
    return {
      result: "https://youtu.be/NVtu_ZI7_QM"
    }
  }
}
</script>

<style lang="css" scoped>

.home-container {
}

@media (min-width: 960px) {
  .trial-container {
    background-color: #F0F8FF;
    padding: 66px 116px;
  }
}

.second-row-container {
  margin-top: 90px;
  margin-bottom: 10%;
}

.video-container {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
    border-radius: 10px !important;
  filter: drop-shadow(1px 5px 12px rgba(0,0,0,21))
}
.video-container iframe, .video-responsive object, .video-responsive embed { 
  height: 100%;
  left:0;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>